import * as actionTypes from '../../actionTypes';
import _ from 'lodash';
import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import {saveOrder} from '../orderActions';

export const cartLoading = (id) => ({
  type: actionTypes.SET_CART_LOADING,
  payload: id,
});

export const locationError = (err) => ({
  type: actionTypes.SET_LOCATION_ERR,
  payload: err,
});

export const clearCart = () => ({
  type: actionTypes.CLEAR_CART,
});

export const setStoreCart = (id) => ({
  type: actionTypes.SET_STORE_CART,
  payload: id,
});

export const addToCart = (item, location) => {
  return (dispatch, getState) => {
    dispatch(cartLoading(item._id));
    const localCart = getState().cart.localCart;
    const storeCart = getState().cart.storeCart;
    const orderType = getState().stores.orderType
      ? getState().stores.orderType
      : 'delivery';
    if (storeCart && storeCart !== '' && storeCart !== item.store_id) {
      dispatch(clearCart());
      dispatch(saveOrder({}));
      dispatch(addToCart(item, location));
    } else {
      dispatch(setStoreCart(item.store_id));
      const add = [...localCart];
      if (item.addons && item.addons.length > 0) {
        const addItemIndex = add.findIndex(
          (val) => val._id === item._id && _.isEqual(val.addons, item.addons)
        );

        const getAddonPrice = (i) => {
          if (i.addons.length === 0) {
            return i.base_price;
          } else {
            let temp = i.base_price;
            i.addons.forEach((obj) => {
              obj.addon_items &&
                obj.addon_items.forEach((addon) => {
                  temp = temp + addon.price;
                });
            });
            return temp;
          }
        };

        if (addItemIndex > -1) {
          add[addItemIndex]['qty'] += 1;
          add[addItemIndex]['total'] =
            getAddonPrice(item) * add[addItemIndex]['qty'];
        } else {
          const tempAdd = {...item};
          tempAdd['qty'] = 1;
          tempAdd['note'] = '';
          tempAdd['total'] = getAddonPrice(item);
          add.push(tempAdd);
        }
      } else {
        const addIndex = add.findIndex((a) => a._id === item._id);
        if (addIndex > -1) {
          add[addIndex]['qty'] += 1;
          add[addIndex]['total'] =
            add[addIndex]['base_price'] * add[addIndex]['qty'];
        } else {
          const tempAdd = {...item};
          tempAdd['qty'] = 1;
          tempAdd['note'] = '';
          tempAdd['total'] = item.base_price;
          add.push(tempAdd);
        }
      }
      const data = {
        items: [],
        platform: 'marketmyindia',
        __type: orderType,
        store_id: item.store_id,
      };
      if (orderType === 'delivery') {
        data['address'] = location;
      }
      add.forEach((item) => {
        const temp = {};
        temp['item_id'] = item._id;
        temp['addons'] = [];
        temp['qty'] = item.qty;
        temp['note'] = item.note;
        if (item.addons && item.addons.length > 0) {
          const tempAddon = [];
          item.addons.forEach((addon) => {
            if (addon.addon_items && addon.addon_items.length > 0) {
              const add = {
                _id: addon._id,
                addon_items: [],
              };
              addon.addon_items.forEach((addonItem) => {
                add['addon_items'].push({_id: addonItem._id});
              });
              tempAddon.push(add);
            }
          });
          temp['addons'] = tempAddon;
        }
        data['items'].push(temp);
      });
      axios
        .post(`${API_URL}/orders/quote`, data)
        .then(async (res) => {
          await dispatch({type: actionTypes.SET_LOCAL_CART, payload: add});
          await dispatch(saveOrder(res.data));
          await dispatch(cartLoading(''));
        })
        .catch((err) => {
          dispatch(locationError(err.response.data.message));
          dispatch(cartLoading(''));
        });
    }
  };
};

export const removeFromCart = (item, location) => {
  return (dispatch, getState) => {
    dispatch(cartLoading(item._id));
    const localCart = getState().cart.localCart;
    const remove = [...localCart];
    const orderType = getState().stores.orderType
      ? getState().stores.orderType
      : 'delivery';
    if (item.addons && item.addons.length > 0) {
      const removeItemIndex = remove.findIndex(
        (val) => val._id === item._id && _.isEqual(val.addons, item.addons)
      );

      const getAddonPrice = (i) => {
        if (i.addons.length === 0) {
          return i.base_price;
        } else {
          let temp = i.base_price;
          i.addons.forEach((obj) => {
            obj.addon_items &&
              obj.addon_items.forEach((addon) => {
                temp = temp + addon.price;
              });
          });
          return temp;
        }
      };
      if (removeItemIndex > -1) {
        if (remove[removeItemIndex]['qty'] === 1) {
          remove.splice(removeItemIndex, 1);
        } else {
          remove[removeItemIndex]['qty'] -= 1;
          remove[removeItemIndex]['total'] =
            getAddonPrice(item) * remove[removeItemIndex]['qty'];
        }
      }
    } else {
      const removeIndex = remove.findIndex((a) => a._id === item._id);
      if (removeIndex > -1) {
        if (remove[removeIndex]['qty'] === 1) {
          remove.splice(removeIndex, 1);
        } else {
          remove[removeIndex]['qty'] -= 1;
          remove[removeIndex]['total'] =
            remove[removeIndex]['base_price'] * remove[removeIndex]['qty'];
        }
      }
    }
    if (remove.length > 0) {
      const data = {
        items: [],
        platform: 'marketmyindia',
        __type: orderType,
        store_id: item.store_id,
      };
      if (orderType === 'delivery') {
        data['address'] = location;
      }
      remove.forEach((item) => {
        const temp = {};
        temp['item_id'] = item._id;
        temp['addons'] = [];
        temp['qty'] = item.qty;
        temp['note'] = item.note;
        if (item.addons && item.addons.length > 0) {
          const tempAddon = [];
          item.addons.forEach((addon) => {
            if (addon.addon_items && addon.addon_items.length > 0) {
              const add = {
                _id: addon._id,
                addon_items: [],
              };
              addon.addon_items.forEach((addonItem) => {
                add['addon_items'].push({_id: addonItem._id});
              });
              tempAddon.push(add);
            }
          });
          temp['addons'] = tempAddon;
        }
        data['items'].push(temp);
      });
      axios
        .post(`${API_URL}/orders/quote`, data)
        .then(async (res) => {
          await dispatch({type: actionTypes.SET_LOCAL_CART, payload: remove});
          await dispatch(saveOrder(res.data));
          await dispatch(cartLoading(''));
        })
        .catch((err) => {
          dispatch(locationError(err.response.data.message));
          dispatch(cartLoading(''));
        });
    } else {
      dispatch(clearCart());
      dispatch(saveOrder({}));
    }
  };
};
