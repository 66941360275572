import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import storeReducers from './storeReducers';
import authReducers from './authReducers';
import cartReducers from './cartReducers';
import orderReducers from './orderReducers';
import bargainReducers from './bargainReducers';
import loyaltyReducers from './loyaltyReducers';
import boltXReducers from './boltXReducers';
import miscReducers from './miscReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    stores: storeReducers,
    auth: authReducers,
    cart: cartReducers,
    order: orderReducers,
    bargain: bargainReducers,
    loyalty: loyaltyReducers,
    boltx: boltXReducers,
    misc: miscReducers,
  });

export default reducers;
