import axios from 'axios';
import * as actionTypes from '../../actionTypes';
import {API_URL} from '../../../utils/constants';

export const storeLoading = (status) => ({
  type: actionTypes.STORE_LOADING,
  payload: status,
});

export const setOrderType = (type) => ({
  type: actionTypes.SET_ORDER_TYPE,
  payload: type,
});

export const saveAvailbaleCategory = (category) => ({
  type: actionTypes.SAVE_AVAILABLE_CATEGORY,
  payload: category,
});

export const itemLoading = (status) => ({
  type: actionTypes.ITEM_LOADING,
  payload: status,
});

export const categoryLoading = (status) => ({
  type: actionTypes.CATEGORY_LOADING,
  payload: status,
});

export const saveStores = (data) => ({
  type: actionTypes.SAVE_STORES,
  payload: data,
});

export const updateStores = (data) => ({
  type: actionTypes.UPDATE_STORES,
  payload: data,
});

export const setStoreType = (type) => ({
  type: actionTypes.STORE_TYPE,
  payload: type,
});

export const setSelectedStore = (store) => ({
  type: actionTypes.SAVE_SELECTED_STORE,
  payload: store,
});

export const setSelectedAddon = (item) => ({
  type: actionTypes.SAVE_SELECTED_ADDON,
  payload: item ? [item] : [],
});

export const saveItems = (items) => ({
  type: actionTypes.SAVE_ITEMS,
  payload: items,
});

export const saveCategories = (categories) => ({
  type: actionTypes.SAVE_CATEGORIES,
  payload: categories,
});

export const setVeg = (status) => ({
  type: actionTypes.SET_VEG,
  payload: status,
});

export const setGlobalSearch = (value) => ({
  type: actionTypes.SET_GLOABL_SEARCH,
  payload: value,
});

export const setActiveSearch = (value) => ({
  type: actionTypes.SET_ACTIVE_SEARCH,
  payload: value,
});

export const nameSearchLoading = (value) => ({
  type: actionTypes.SET_NAME_SEARCH,
  payload: value,
});

export const setNameSearchData = (stores) => ({
  type: actionTypes.SET_NAME_SEARCH_DATA,
  payload: stores,
});

export const setSearchItem = (value) => {
  return async (dispatch, getState) => {
    const sourceItems = getState().stores.sourceItems;
    const list = sourceItems.filter((a) =>
      a.name['en'].toLowerCase().includes(value.toLowerCase())
    );
    await dispatch({type: actionTypes.SET_SEARCH_ITEM, payload: value});
    await dispatch({type: actionTypes.SET_FILTERED_ITEMS, payload: list});
  };
};

export const getStoreCategory = (latitude, longitude) => {
  return (dispatch) => {
    dispatch(storeLoading(true));
    axios
      .get(
        `${API_URL}/stores/types?delivery=true&platforms=marketmyindia&lat=${latitude}&lng=${longitude}&limit=100`
      )
      .then(async (res) => {
        await dispatch(saveAvailbaleCategory(res.data));
        await dispatch(storeLoading(false));
      })
      .catch((err) => {
        console.log('err', err);
        dispatch(storeLoading(false));
      });
  };
};

export const getStores = (type, latitude, longitude) => {
  return (dispatch) => {
    dispatch(storeLoading(true));
    const t = type.replace(/&/g, '%26');
    axios
      .get(
        `${API_URL}/stores?store_types=${t}&delivery=true&platforms=marketmyindia&lat=${latitude}&lng=${longitude}&limit=10&page=1`
      )
      .then(async (res) => {
        await dispatch(saveStores(res.data));
        await dispatch(storeLoading(false));
      })
      .catch((err) => {
        console.log('err', err);
        dispatch(storeLoading(false));
      });
  };
};

export const getSearchStore = (value, type, latitude, longitude) => {
  return (dispatch) => {
    dispatch(storeLoading(true));
    const t = type.replace(/&/g, '%26');
    axios
      .get(
        `${API_URL}/stores?search=${value}&store_types=${t}&delivery=true&stores=true&platforms=marketmyindia&lat=${latitude}&lng=${longitude}&limit=10&page=1`
      )
      .then(async (res) => {
        await dispatch(saveStores(res.data));
        await dispatch(storeLoading(false));
      })
      .catch((err) => {
        console.log('err', err);
        dispatch(storeLoading(false));
      });
  };
};

export const getStoreListPagination = (url) => {
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(updateStores(res.data));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
};
export const getStore = (id) => {
  return (dispatch) => {
    dispatch(storeLoading(true));
    axios
      .get(`${API_URL}/stores/${id}`)
      .then(async (res) => {
        await dispatch(setSelectedStore(res.data));
        await dispatch(storeLoading(false));
      })
      .catch((err) => {
        console.log('err', err);
        dispatch(setSelectedStore({}));
        dispatch(storeLoading(false));
      });
  };
};

export const getCategories = (id) => {
  return (dispatch) => {
    dispatch(categoryLoading(true));
    axios
      .get(`${API_URL}/stores/${id}/category`)
      .then(async (res) => {
        await dispatch(saveCategories(res.data));
        await dispatch(categoryLoading(false));
      })
      .catch(() => {
        dispatch(categoryLoading(false));
      });
  };
};

export const getItems = (id) => {
  return (dispatch, getState) => {
    const veg = getState().stores.veg;
    dispatch(itemLoading(true));
    axios
      .get(`${API_URL}/stores/${id}/items?veg=${veg}&limit=5000&page=1`)
      .then(async (res) => {
        await dispatch(saveItems(res.data));
        await dispatch(itemLoading(false));
      })
      .catch(() => {
        dispatch(itemLoading(false));
      });
  };
};

export const fetchGloabalSearch = (value, lat, lng) => {
  return (dispatch) => {
    dispatch(storeLoading(true));
    axios
      .get(
        `${API_URL}/stores?delivery=true&platforms=marketmyindia&search=${value}&lat=${lat}&lng=${lng}`
      )
      .then((res) => {
        dispatch(storeLoading(false));
        dispatch(setGlobalSearch(res.data));
      })
      .catch(() => {
        dispatch(storeLoading(false));
        dispatch(setGlobalSearch([]));
      });
  };
};

export const getSearchStoreName = (value, lat, lng) => {
  return (dispatch) => {
    dispatch(nameSearchLoading(true));
    axios
      .get(
        `${API_URL}/stores/vname?delivery=true&platforms=marketmyindia&visible_name=${value}`
      )
      .then((res) => {
        dispatch(setNameSearchData(res.data));
        dispatch(nameSearchLoading(false));
      })
      .catch(() => {
        dispatch(
          setNameSearchData({
            result: [],
            __metadata: {},
          })
        );
        dispatch(nameSearchLoading(false));
      });
  };
};

export const getSearchStoreNamePagination = (url) => {
  return (dispatch) => {
    axios
      .get(url)
      .then((res) =>
        dispatch({
          type: actionTypes.SET_NAME_SEARCH_DATA_PAGINATION,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err));
  };
};
