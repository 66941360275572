import * as actionTypes from '../../actionTypes';

const initialState = {
  loadingUser: false,
  bargainLoading: false,
  orderLoading: false,
  fetchPayment: false,
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_USER:
      return {
        ...state,
        loadingUser: action.payload,
      };
    case actionTypes.BARGAIN_LOADING:
      return {
        ...state,
        bargainLoading: action.payload,
      };
    case actionTypes.LOADING_ORDER:
      return {
        ...state,
        orderLoading: action.payload,
      };
    case actionTypes.FETCH_PAYMENT_STATUS:
      return {
        ...state,
        fetchPayment: action.payload,
      };
    case actionTypes.CLEAR_USER:
      return {
        loadingUser: false,
        bargainLoading: false,
        orderLoading: false,
        fetchPayment: false,
      };
    default:
      return state;
  }
}
