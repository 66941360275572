export const STORE_LOADING = 'STORE_LOADING';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SAVE_AVAILABLE_CATEGORY = 'SAVE_AVAILABLE_CATEGORY';
export const ITEM_LOADING = 'ITEM_LOADING';
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const SAVE_STORES = 'SAVE_STORES';
export const UPDATE_STORES = 'UPDATE_STORES';
export const SAVE_SELECTED_STORE = 'SAVE_SELECTED_STORE';
export const SAVE_SELECTED_ADDON = 'SAVE_SELECTED_ADDON';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const SAVE_ITEMS = 'SAVE_ITEMS';
export const SET_FILTERED_ITEMS = 'SET_FILTERED_ITEMS';
export const SET_VEG = 'SET_VEG';
export const STORE_TYPE = 'STORE_TYPE';
export const SET_SEARCH_ITEM = 'SET_SEARCH_ITEM';
export const SET_GLOABL_SEARCH = 'SET_GLOABL_SEARCH';
export const SET_ACTIVE_SEARCH = 'SET_ACTIVE_SEARCH';
export const SET_NAME_SEARCH = 'SET_NAME_SEARCH';
export const SET_NAME_SEARCH_DATA = 'SET_NAME_SEARCH_DATA';
export const SET_NAME_SEARCH_DATA_PAGINATION =
  'SET_NAME_SEARCH_DATA_PAGINATION';
export const RESET_STORES = 'RESET_STORES';
