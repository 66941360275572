import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';

export const setCountryCode = (code) => {
  return {
    type: actionTypes.COUNTRY_CODE,
    payload: code,
  };
};

export const setMobileNumber = (number) => {
  return {
    type: actionTypes.MOBILE_NUMBER,
    payload: number,
  };
};

export const getOTP = (phoneNumber) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/auth/sendotp`, {
        phone: phoneNumber,
      })
      .then((res) => {
        dispatch({type: actionTypes.OTP_SENT, payload: true});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const resendOTP = (phoneNumber) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/auth/resendotp`, {
        phone: phoneNumber,
      })
      .then(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const loginUser = (data, url, redirect) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOGIN_API_LOADING, payload: true});
    axios
      .post(`${API_URL}/users/auth/login`, data)
      .then((res) => {
        localStorage.setItem('auth_token', res.data.token);
        dispatch({type: actionTypes.LOGIN_SUCCESS, payload: res.data});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
        if (res.data.user.current_order) {
          redirect(url);
        } else {
          redirect(url, true);
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOGIN_ERROR, payload: true});
        dispatch({type: actionTypes.LOGIN_API_LOADING, payload: false});
      });
  };
};

export const updateUserAddress = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_USER, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_USER,
          payload: false,
        });
        if (
          res.data.user &&
          res.data.user.addresses &&
          res.data.user.addresses.length > 0
        ) {
          if (cb) {
            cb(res.data.user.addresses[res.data.user.addresses.length - 1]);
          }
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_USER, payload: false});
      });
  };
};

export const saveUserCard = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_USER, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_USER,
          payload: false,
        });
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        if (cb) {
          cb();
        }
        dispatch({type: actionTypes.LOADING_USER, payload: false});
      });
  };
};

export const fetchUserDetails = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/users`)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_LOGIN_DATA, payload: res.data});
      })
      .catch(() => {});
  };
};

export const updateUserDetails = (details, cb) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_USER, payload: true});
    http
      .put(`${API_URL}/users`, details)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_LOGIN_DATA,
          payload: res.data.user,
        });
        await dispatch({
          type: actionTypes.LOADING_USER,
          payload: false,
        });
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_USER, payload: false});
        if (cb) {
          cb();
        }
      });
  };
};

export const resetLogin = () => {
  return {
    type: actionTypes.OTP_SENT,
    payload: false,
  };
};

export const resetLoginErr = () => {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: false,
  };
};

export const logoutUser = () => ({
  type: actionTypes.CLEAR_USER,
});
