import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PaymentRouteHoc({component: Component, ...rest}) {
  const orderID = useSelector(
    (state) => state.order.order && state.order.order._id
  );
  return (
    <Route
      {...rest}
      render={(prop) =>
        orderID ? <Component {...prop} /> : <Redirect to={'/home'} />
      }
    />
  );
}

export default PaymentRouteHoc;
