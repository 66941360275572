import React from 'react';
import Lottie from 'react-lottie';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {useSelector, useDispatch} from 'react-redux';
import * as animationDataLoading from '../../assets/animations/boltLoading.json';
import * as noStoreAnimationData from '../../assets/animations/no-store-animation.json';
import {setPaymentFetch, locationError} from '../../services/actions';
import {useHistory} from 'react-router';
import {push} from 'connected-react-router';

function LoadingModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingUser = useSelector((state) => state.misc.loadingUser);
  const orderLoading = useSelector((state) => state.misc.orderLoading);
  const fetchPayment = useSelector((state) => state.misc.fetchPayment);
  const locationErr = useSelector((state) => state.cart.locationErr);
  const defaultLoading = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultNoStoreLoading = {
    loop: true,
    autoplay: true,
    animationData: noStoreAnimationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleRetry = () => {
    dispatch(setPaymentFetch(false));
  };

  const handlelocation = () => {
    dispatch(locationError(''));
    dispatch(push(`/search-location?ref=${history.location.pathname}`));
  };

  const CustomDialog = withStyles({
    root: {
      zIndex: '1500 !important',
      '& div': {
        '& div': !fetchPayment && {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
  })((props) => <Dialog maxWidth={'xs'} {...props} />);
  return (
    <>
      <CustomDialog
        open={
          Boolean(loadingUser) || Boolean(orderLoading) || Boolean(fetchPayment)
        }
      >
        <DialogContent
          style={{
            padding: 10,
            textAlign: 'center',
            paddingRight: fetchPayment ? 10 : 40,
          }}
        >
          <Lottie
            options={defaultLoading}
            height={100}
            width={100}
            isClickToPauseDisabled={true}
          />
          {fetchPayment && (
            <div style={{marginTop: 16}}>
              Please open your UPI app and accept the payment request
            </div>
          )}
          {fetchPayment && (
            <Button
              style={{
                fontSize: 12,
                marginTop: 16,
                color: '#EB5757',
              }}
              onClick={handleRetry}
              size={'small'}
            >
              Retry
            </Button>
          )}
        </DialogContent>
      </CustomDialog>
      <Dialog open={Boolean(locationErr && locationErr !== '')}>
        <DialogContent
          style={{
            padding: 16,
            textAlign: 'center',
          }}
        >
          <Lottie
            options={defaultNoStoreLoading}
            height={200}
            width={200}
            isClickToPauseDisabled={true}
          />
          <div style={{marginTop: 16}}>{locationErr}</div>
          <Button
            style={{
              fontSize: 12,
              marginTop: 16,
              color: '#EB5757',
            }}
            onClick={handlelocation}
            size={'small'}
          >
            Change location
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoadingModal;
