import {createMuiTheme} from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {main: '#FFDF82'},
    secondary: {main: '#212121'},
    background: {
      base: '#E5E5E5',
      default2:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
      default1:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #121212',
      default: '#FFFFFF',
    },
    text: {
      primary: '#212121',
      primary1: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
  spacing: 2,
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {main: '#FFDF82', muted: 'rgba(225, 223, 130, 0.25)'},
    secondary: {main: '#FFFFFF'},
    background: {
      base: '#150619',
      footer: '#2E2E2E',
      notFound: 'linear-gradient(180deg, rgb(27, 19, 26) 0%, #FFFFFF 90%)',
      notFoundBottom:
        'linear-gradient(180deg, rgb(67, 67, 67) 0%, rgb(57, 57, 57) 100%)',
      card:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #121212',
      default3:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212',
      default2:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #121212',
      default1:
        'linear-gradient(0deg, rgba(255, 218, 128, 0.04), rgba(255, 218, 128, 0.04)), #121212',
      default:
        'linear-gradient(0deg, rgba(254, 189, 17, 0.02), rgba(254, 189, 17, 0.02)), #121212',
    },
    text: {
      primary: 'rgba(255, 255, 255, 1.00)',
      primary1: 'rgba(255, 255, 255, 0.87)',
      primary2: 'rgba(255, 255, 255, 0.57)',
      primary3: 'rgba(255, 255, 255, 0.38)',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
      inverted: 'rgba(0, 0, 0, 0.87)',
    },
    border: {
      dashed: '0.5px dashed rgba(255, 255, 255, 0.56)',
      normal: '0.5px solid rgba(255, 255, 255, 0.25)',
    },
    boxShadow: {
      footer:
        '0px -2px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
  spacing: 2,
});
