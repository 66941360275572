import * as actionTypes from '../../actionTypes';
import _ from 'lodash';
import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import {toast} from 'react-toastify';
import {push} from 'connected-react-router';

export const setBoltxDemo = (status) => ({
  type: actionTypes.SET_BOLTX_DEMO,
  payload: status,
});

export const setBoltxSearchResult = (data) => ({
  type: actionTypes.SET_BOLTX_SEARCH_RESULT,
  payload: data,
});

export const updateBoltxSearchResult = (data) => ({
  type: actionTypes.UPDATE_BOLTX_SEARCH_RESULT,
  payload: data,
});

export const clearBoltx = () => ({
  type: actionTypes.CLEAR_BOLTX_CART,
});

export const searchBoltXItem = (search, lat, lng) => {
  return (dispatch) => {
    axios
      .get(
        `${API_URL}/items/boltx/search?search=${search}&lat=${lat}&lng=${lng}&limit=10&page=1`
      )
      .then((res) => {
        dispatch(setBoltxSearchResult(res.data));
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch(
          setBoltxSearchResult({
            result: [],
            __metadata: {},
          })
        );
      });
  };
};

export const getSearchBoltXItemPagination = (url) => {
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(updateBoltxSearchResult(res.data));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
};

export const boltXAddtoCart = (item, offer, lat, lng) => {
  return (dispatch, getState) => {
    const boltxCart = getState().boltx.boltxCart;
    const add = [...boltxCart];
    if (item.addons && item.addons.length > 0) {
      const addItemIndex = add.findIndex(
        (val) => val._id === item._id && _.isEqual(val.addons, item.addons)
      );

      const getAddonPrice = (i) => {
        if (i.addons.length === 0) {
          return i.base_price;
        } else {
          let temp = i.base_price;
          i.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };

      if (addItemIndex > -1) {
        add[addItemIndex]['qty'] += 1;
        add[addItemIndex]['total'] =
          getAddonPrice(item) * add[addItemIndex]['qty'];
      } else {
        const tempAdd = {...item};
        tempAdd['qty'] = 1;
        tempAdd['note'] = '';
        tempAdd['total'] = getAddonPrice(item);
        add.push(tempAdd);
      }
    } else {
      const addIndex = add.findIndex((a) => a._id === item._id);
      if (addIndex > -1) {
        add[addIndex]['qty'] += 1;
        add[addIndex]['total'] =
          add[addIndex]['base_price'] * add[addIndex]['qty'];
      } else {
        const tempAdd = {...item};
        tempAdd['qty'] = 1;
        tempAdd['note'] = '';
        tempAdd['total'] = item.base_price;
        add.push(tempAdd);
      }
    }
    dispatch({type: actionTypes.SET_BOLTX_CART, payload: add});
    if (offer) {
      dispatch(getBoltxOffer(lat, lng));
    }
  };
};

export const boltXRemoveFromCart = (item, offer, lat, lng) => {
  return (dispatch, getState) => {
    const boltxCart = getState().boltx.boltxCart;
    const remove = [...boltxCart];
    if (item.addons && item.addons.length > 0) {
      const removeItemIndex = remove.findIndex(
        (val) => val._id === item._id && _.isEqual(val.addons, item.addons)
      );

      const getAddonPrice = (i) => {
        if (i.addons.length === 0) {
          return i.base_price;
        } else {
          let temp = i.base_price;
          i.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };
      if (removeItemIndex > -1) {
        if (remove[removeItemIndex]['qty'] === 1) {
          remove.splice(removeItemIndex, 1);
        } else {
          remove[removeItemIndex]['qty'] -= 1;
          remove[removeItemIndex]['total'] =
            getAddonPrice(item) * remove[removeItemIndex]['qty'];
        }
      }
    } else {
      const removeIndex = remove.findIndex((a) => a._id === item._id);
      if (removeIndex > -1) {
        if (remove[removeIndex]['qty'] === 1) {
          remove.splice(removeIndex, 1);
        } else {
          remove[removeIndex]['qty'] -= 1;
          remove[removeIndex]['total'] =
            remove[removeIndex]['base_price'] * remove[removeIndex]['qty'];
        }
      }
    }
    dispatch({type: actionTypes.SET_BOLTX_CART, payload: remove});
    if (offer && boltxCart.length > 0) {
      dispatch(getBoltxOffer(lat, lng));
    }
  };
};

export const getBoltxOffer = (lat, lng, forwardPush) => {
  return (dispatch, getState) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const boltxCart = getState().boltx.boltxCart;
    if (boltxCart.length > 0) {
      const data = [];
      boltxCart.forEach((cart) => {
        const temp = {};
        temp['name'] = cart.name.en;
        temp['quantity'] = cart.qty;
        data.push(temp);
      });
      axios
        .post(`${API_URL}/stores/boltx/quotes?lat=${lat}&lng=${lng}`, data)
        .then((res) => {
          dispatch({type: actionTypes.SET_BOLTX_OFFERS, payload: res.data});
          if (forwardPush) {
            dispatch(push('/bolt-x/offers'));
          }
          dispatch({type: actionTypes.LOADING_ORDER, payload: false});
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            toast.error(err.response.data.message);
          }
          dispatch({type: actionTypes.LOADING_ORDER, payload: false});
        });
    } else {
      dispatch({type: actionTypes.LOADING_ORDER, payload: false});
    }
  };
};
