import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateCurrentOrder, ablyAck} from './actions';
import {getOrderDetails} from './actions/orderActions';

function Ably() {
  const dispatch = useDispatch();
  const orderID = useSelector(
    (state) => state.order.order && state.order.order._id
  );
  const userID = useSelector(
    (state) =>
      state.auth.userData &&
      state.auth.userData.user &&
      state.auth.userData.user._id
  );
  /** connect to order channel when orderID updated */
  useEffect(() => {
    if (orderID && window.orderChannel) {
      const orderListener = (message) => {
        console.log('order----->', message.data);
        dispatch(ablyAck(message.data.ably_id));
        dispatch(updateCurrentOrder(message.data.data.order_id));
      };
      window.orderChannel.attach();
      window.orderChannel.once('attached', () => {
        console.log('%cably order channel connected', 'color: green;');
        window.orderChannel.subscribe('order', orderListener);
      });
    }
  }, [orderID, dispatch]);

  useEffect(() => {
    if (userID && window.userChannel) {
      const ordersListener = (message) => {
        console.log('userOrders---->', message.data);
        dispatch(ablyAck(message.data.ably_id));
        if (message.data.data && message.data.data.order_id) {
          dispatch(getOrderDetails(message.data.data.order_id));
        }
      };
      window.userChannel.attach();
      window.userChannel.once('attached', () => {
        console.log('%cably user channel connected', 'color: green;');
        // window.userChannel.subscribe('user', userListener);
        window.userChannel.subscribe('order', ordersListener);
      });
    }
    // eslint-disable-next-line
  }, [userID]);

  return <></>;
}

export default Ably;
