/* eslint-disable camelcase,no-console,no-undef,valid-jsdoc */
import axios from 'axios';
import {API_URL} from '../utils/constants';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const instance = axios.create({
  baseURL: API_URL,
  contentType: 'application/json',
  Authorization: getAuthToken(),
});

function getAuthToken() {
  if (localStorage.getItem('auth_token')) {
    return `Bearer ${localStorage.getItem('auth_token')}`;
  }
}

/** Getting refresh token and retry */
const parseError = (error) => {
  const originalRequest = error.config;
  if (
    error.response.data.message ===
    "Cannot destructure property 'type' of 'jwt.decode(...)' as it is null."
  ) {
    window.localStorage.clear();
    window.location.reload();
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({resolve, reject});
      })
        .then((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise(function (resolve, reject) {
      axios
        .get(`${API_URL}/auth/token`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
          },
        })
        .then((res) => {
          localStorage.setItem('auth_token', res.data.token);
          localStorage.setItem('refresh_token', res.data.newRefreshToken);
          instance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${localStorage.getItem('auth_token')}`;
          originalRequest.headers['Authorization'] = `Bearer ${res.data.token}`;
          processQueue(null, res.data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
          setTimeout(() => {
            window.localStorage.clear();
            window.location.reload();
          }, 1000);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

/** Configuration for headers */
const parseConfig = (config) => {
  const token =
    localStorage && localStorage.getItem('auth_token')
      ? localStorage.getItem('auth_token')
      : '';
  const configuration = {...config};
  configuration.headers.Authorization = `Bearer ${token}`;
  return configuration;
};

// request header
instance.interceptors.request.use(
  (config) => parseConfig(config),
  (error) => Promise.reject(error)
);

// response parse
instance.interceptors.response.use(
  (response) => response,
  (error) => parseError(error)
);

export const http = instance;
