import * as actionTypes from '../../actionTypes';
import {http} from '../../http';
import {saveOrder} from '../orderActions';
import {goBack, push} from 'connected-react-router';
import {API_URL} from '../../../utils/constants';
import {toast} from 'react-toastify';

export const initMsg = () => ({
  type: actionTypes.CLEAR_BARGAIN,
});

export const addMsg = (msg) => ({
  type: actionTypes.ADD_MESSAGE,
  payload: msg,
});

export const setUserConfirmation = (status) => ({
  type: actionTypes.USER_CONFIRMATION,
  payload: status,
});

export const setUserAttempt = (attempt) => ({
  type: actionTypes.SET_USER_ATTEMPT,
  payload: attempt,
});

export const setBargainLoading = (status) => ({
  type: actionTypes.BARGAIN_LOADING,
  payload: status,
});

export const setFinalPrice = (price) => ({
  type: actionTypes.SET_FINAL_PRICE,
  payload: price,
});

export const setFinishBargain = (status) => ({
  type: actionTypes.SET_FINISH_BARGAIN,
  payload: status,
});

export const getBargain = () => {
  return (dispatch, getState) => {
    const orderID = getState().order.order && getState().order.order._id;
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}/bargain`)
      .then((res) => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
        dispatch({type: actionTypes.BARGAIN_DATA, payload: res.data});
        dispatch(push('/bargain'));
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const initiateBot = () => {
  return async (dispatch) => {
    await dispatch(initMsg());
    await dispatch(
      addMsg({
        msg: 'Hey, Let’s see who win this bargain game',
        msgBy: 'bolt',
      })
    );
    await dispatch(
      addMsg({
        msg: 'What’s your best price?',
        msgBy: 'bolt',
      })
    );
  };
};

export const userReply = (data, nextData, attempt) => {
  return async (dispatch) => {
    await dispatch(setBargainLoading(true));
    await dispatch(
      addMsg({
        msg: data.msg,
        msgBy: 'user',
      })
    );
    await dispatch(setUserAttempt(attempt + 1));
    await dispatch(setUserConfirmation(true));
    if (nextData) {
      switch (attempt) {
        case 0:
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: 'You Kidding? Right',
                msgBy: 'bolt',
              })
            );
          }, 1000);
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: `Can you do ${nextData.msg} please?`,
                msgBy: 'bolt',
              })
            );
            await dispatch(setFinalPrice(nextData.discount_amount));
            await dispatch(setBargainLoading(false));
          }, 2000);
          break;
        case 1:
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: 'Noooo',
                msgBy: 'bolt',
              })
            );
          }, 1000);
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: `${data.msg} is really low`,
                msgBy: 'bolt',
              })
            );
          }, 2000);
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: `I can do ${nextData.msg}`,
                msgBy: 'bolt',
              })
            );
            await dispatch(setFinalPrice(nextData.discount_amount));
            await dispatch(setBargainLoading(false));
          }, 3000);
          break;
        default:
          setTimeout(async () => {
            await dispatch(
              addMsg({
                msg: `Can you do ${nextData.msg} please?`,
                msgBy: 'bolt',
              })
            );
            await dispatch(setFinalPrice(nextData.discount_amount));
            await dispatch(setBargainLoading(false));
          }, 1000);
      }
    }
  };
};

export const userPositiveReply = (data) => {
  return async (dispatch) => {
    await dispatch(setBargainLoading(true));
    await dispatch(
      addMsg({
        msg: data.msg,
        msgBy: 'user',
      })
    );
    await dispatch(setFinalPrice(data.discount_amount));
    setTimeout(async () => {
      await dispatch(
        addMsg({
          msg: "Ok! Let's shake on it",
          msgBy: 'bolt',
        })
      );
    }, 1000);
    setTimeout(async () => {
      await dispatch(
        addMsg({
          msg: 'Applying bargain will remove all other discounts',
          msgBy: 'bolt',
          apply: true,
        })
      );
      await dispatch(setFinishBargain(true));
      await dispatch(setBargainLoading(false));
    }, 2000);
  };
};

export const userConfirmationReply = (state, userAttempt) => {
  return async (dispatch) => {
    await dispatch(setBargainLoading(true));
    if (state === 'accept') {
      await dispatch(
        addMsg({
          msg: 'Yes! Of course',
          msgBy: 'user',
        })
      );
      setTimeout(async () => {
        await dispatch(
          addMsg({
            msg: "Ok! Let's shake on it",
            msgBy: 'bolt',
          })
        );
      }, 1000);
      if (userAttempt >= 2) {
        setTimeout(async () => {
          await dispatch(
            addMsg({
              msg: `You are tough`,
              msgBy: 'bolt',
            })
          );
        }, 2000);
      }
      setTimeout(async () => {
        await dispatch(
          addMsg({
            msg: 'Applying bargain will remove all other discounts',
            msgBy: 'bolt',
            apply: true,
          })
        );
        await dispatch(setUserConfirmation(false));
        await dispatch(setFinishBargain(true));
        await dispatch(setBargainLoading(false));
      }, 3000);
    } else if (state === 'reject') {
      await dispatch(
        addMsg({
          msg: 'No! That’s too much',
          msgBy: 'user',
        })
      );
      if (userAttempt >= 2) {
        setTimeout(async () => {
          await dispatch(
            addMsg({
              msg: 'You are killin me',
              msgBy: 'bolt',
            })
          );
        }, 1000);
        setTimeout(async () => {
          await dispatch(
            addMsg({
              msg: 'Give me your last price',
              msgBy: 'bolt',
            })
          );
        }, 2000);
      } else {
        setTimeout(async () => {
          await dispatch(
            addMsg({
              msg: 'Okk! Give me your best price',
              msgBy: 'bolt',
            })
          );
        }, 1000);
      }
      setTimeout(async () => {
        await dispatch(setUserConfirmation(false));
        await dispatch(setBargainLoading(false));
      }, 2000);
    }
  };
};

export const applyBargain = (orderID, data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOADING_ORDER,
      payload: true,
    });
    http
      .put(`${API_URL}/orders/${orderID}`, {
        discount: data,
      })
      .then(async (res) => {
        await dispatch(saveOrder(res.data));
        await dispatch(goBack());
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};
