import * as actionTypes from '../../actionTypes';

const initialState = {
  previousOrders: {
    result: [],
    __metadata: {},
  },
  previousOrdersError: {},
  previousOrdersPending: false,
  order: {},
  bargainValue: null,
  orderAddress: {},
  coupons: [],
  trackOrder: {},
};

export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.SAVE_TRACK_ORDER:
      return {
        ...state,
        trackOrder: action.payload,
      };
    case actionTypes.SELECTED_ORDER_ADDRESS:
      const temp = {...state.order};
      if (temp._id) {
        delete temp._id;
      }
      return {
        ...state,
        order: {...temp},
        orderAddress: action.payload,
      };
    case actionTypes.SET_BARGAIN_VALUE:
      return {
        ...state,
        bargainValue: action.payload,
      };
    case actionTypes.RESET_ORDER:
      return {
        previousOrders: {
          result: [],
          __metadata: {},
        },
        previousOrdersError: {},
        previousOrdersPending: false,
        order: {},
        bargainValue: null,
        orderAddress: {},
        coupons: [],
        trackOrder: {},
      };
    case actionTypes.SAVE_COUPONS:
      const tempCoupon = [];
      if (action.payload.rewards && action.payload.rewards.length > 0) {
        action.payload.rewards.forEach((reward) => {
          const rew = {...reward.voucher};
          rew['discount_type'] = 'reward';
          rew['_id'] = reward._id;
          tempCoupon.push(rew);
        });
      }
      if (action.payload.vouchers && action.payload.vouchers.length > 0) {
        action.payload.vouchers.forEach((voucher) => {
          const vou = {...voucher};
          vou['discount_type'] = 'voucher';
          tempCoupon.push(vou);
        });
      }
      return {
        ...state,
        coupons: tempCoupon,
      };
    case actionTypes.GET_PREV_ORDERS_PENDING:
      return {
        ...state,
        previousOrdersPending: true,
      };
    case actionTypes.GET_PREV_ORDERS_SUCCESS:
      return {
        ...state,
        previousOrders: action.payload,
        previousOrdersPending: false,
      };
    case actionTypes.SET_PREV_ORDERS_PAGINATION: {
      const temp = {...state.previousOrders};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        previousOrders: temp,
      };
    }
    case actionTypes.GET_PREV_ORDERS_FAILED:
      return {
        ...state,
        previousOrdersError: action.payload,
        previousOrdersPending: true,
      };
    case actionTypes.GET_ORDER_DETAILS_PENDING:
      return {
        ...state,
        orderDetailsPending: true,
      };
    case actionTypes.GET_ORDER_DETAILS_SUCCESS:
      const tempOrders = {...state.previousOrders};
      let tempTrackOrder = {...state.trackOrder};
      if (tempTrackOrder && tempTrackOrder._id === action.payload._id) {
        tempTrackOrder = {...action.payload};
      }
      if (tempOrders.result && tempOrders.result.length > 0) {
        const tempIndex = tempOrders.result.findIndex(
          (a) => a._id === action.payload._id
        );
        if (tempIndex > -1) {
          tempOrders.result[tempIndex] = {
            ...action.payload,
            store_id: tempOrders.result[tempIndex].store_id,
          };
        } else {
          tempOrders['result'].push(action.payload);
        }
      }
      return {
        ...state,
        previousOrdersPending: false,
        previousOrders: tempOrders,
        trackOrder: tempTrackOrder,
      };
    default:
      return state;
  }
}
