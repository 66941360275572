import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {lightTheme, darkTheme} from './utils/themes/theme';
import './App.scss';
import {Route, Switch} from 'react-router';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suspenseHoc from './hoc/suspenseHoc';
import LandingRouteHoc from './hoc/LandingRouteHoc';
import LocationRouteHoc from './hoc/LocationRouteHoc';
import PrivateRouteHoc from './hoc/PrivateRouteHoc';
import PaymentRouteHoc from './hoc/PaymentRouteHoc';
import LoadingModal from './modules/LoadingModal';
import Ably from './services/Ably';

const NotFound = lazy(() => import('./modules/NotFound'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SearchLocation = lazy(() => import('./pages/SearchLocation'));
const HomePage = lazy(() => import('./pages/HomePage'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const StoreListPage = lazy(() => import('./pages/StoreListPage'));
const StorePage = lazy(() => import('./pages/StorePage'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const ManageAddressPage = lazy(() => import('./pages/ManageAddressPage'));
const SavedCardsPage = lazy(() => import('./pages/SavedCardsPage'));
// const LoyaltyPage = lazy(() => import('./pages/LoyaltyPage'));
const OrderTrackingPage = lazy(() => import('./pages/OrderTrackingPage'));
const CartPage = lazy(() => import('./pages/CartPage'));
const BargainBotPage = lazy(() => import('./pages/BargainBotPage'));
const StoreSearchPage = lazy(() => import('./pages/StoreSearchPage'));
const BoltXPage = lazy(() => import('./pages/BoltXPage'));
const BoltXOfferPage = lazy(() => import('./pages/BoltXOfferPage'));
const CouponPage = lazy(() => import('./pages/CouponPage'));

function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '0.05.11j');
      } else if (appVersion !== '0.05.11j') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '0.05.11j');
          window.location.reload();
        }, 2000);
      }
    };
  }, []);

  // useEffect(() => {
  //   const cookieOption = {
  //     path: '/',
  //   };
  //   console.log("history.loca", history.location)
  //   if (history.location.search !== '') {
  //     const values = queryString.parse(history.location.search);
  //     if (values.affiliator_id) {
  //       console.log("setcokkies", values.affiliator_id)
  //       setCookies('affiliator_id', values.affiliator_id, cookieOption);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [history.location]);

  const theme = window.localStorage.theme ? window.localStorage.theme : 'dark';

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <Switch>
        <LandingRouteHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route
          exact
          path="/search-location"
          component={suspenseHoc(SearchLocation)}
        />
        <LocationRouteHoc
          exact
          path="/home"
          component={suspenseHoc(HomePage)}
        />
        <LocationRouteHoc
          exact
          path="/stores"
          component={suspenseHoc(StoreListPage)}
        />
        <PrivateRouteHoc
          exact
          path="/profile"
          component={suspenseHoc(ProfilePage)}
        />
        <PrivateRouteHoc
          exact
          path="/profile/address"
          component={suspenseHoc(ManageAddressPage)}
        />
        <PrivateRouteHoc
          exact
          path="/profile/saved_cards"
          component={suspenseHoc(SavedCardsPage)}
        />
        {/* <PrivateRouteHoc
          exact
          path="/profile/loyalty"
          component={suspenseHoc(LoyaltyPage)}
        /> */}
        <PrivateRouteHoc
          path="/track"
          component={suspenseHoc(OrderTrackingPage)}
        />
        <LocationRouteHoc
          exact
          path="/search"
          component={suspenseHoc(SearchPage)}
        />
        <LocationRouteHoc path="/cart" component={suspenseHoc(CartPage)} />
        <LocationRouteHoc path="/coupon" component={suspenseHoc(CouponPage)} />
        <PrivateRouteHoc path="/profile" component={suspenseHoc(ProfilePage)} />
        <PaymentRouteHoc
          exact
          path="/payment"
          component={suspenseHoc(PaymentPage)}
        />
        <Route exact path="/store/:id" component={suspenseHoc(StorePage)} />
        <LocationRouteHoc
          exact
          path="/bargain"
          component={suspenseHoc(BargainBotPage)}
        />
        <LocationRouteHoc
          exact
          path="/bolt-x"
          component={suspenseHoc(BoltXPage)}
        />
        <LocationRouteHoc
          exact
          path="/bolt-x/offers"
          component={suspenseHoc(BoltXOfferPage)}
        />
        <Route exact path="/login" component={suspenseHoc(LoginPage)} />
        <Route exact path="/:id" component={suspenseHoc(StoreSearchPage)} />
        <Route component={suspenseHoc(NotFound)} />
      </Switch>
      <LoadingModal />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        pauseOnHover={false}
      />
      <Ably />
    </ThemeProvider>
  );
}

export default App;
