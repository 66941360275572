import * as actionTypes from '../../actionTypes';

const initialState = {
  orderType: 'delivery',
  availableCategory: [],
  storeList: {
    result: [],
    __metadata: {},
  },
  storeLoading: false,
  categoryLoading: false,
  itemLoading: false,
  selectedStore: {},
  categories: [],
  sourceItems: [],
  filterdItems: [],
  selectedAddon: [],
  veg: false,
  storeType: '',
  searchItem: '',
  globalSearch: {
    result: [],
    __metadata: {},
  },
  activeSearch: 'stores',
  nameSearch: false,
  nameSearchData: {
    result: [],
    __metadata: {},
  },
};

export default function storeReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
      };
    case actionTypes.SAVE_STORES:
      return {
        ...state,
        storeList: action.payload,
      };
    case actionTypes.UPDATE_STORES: {
      const temp = {...state.storeList};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        storeList: temp,
      };
    }
    case actionTypes.SAVE_AVAILABLE_CATEGORY:
      return {
        ...state,
        availableCategory: action.payload,
      };
    case actionTypes.STORE_LOADING:
      return {
        ...state,
        storeLoading: action.payload,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: action.payload,
      };
    case actionTypes.CATEGORY_LOADING:
      return {
        ...state,
        categoryLoading: action.payload,
      };
    case actionTypes.SAVE_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.payload,
      };
    case actionTypes.SAVE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.SAVE_ITEMS:
      return {
        ...state,
        sourceItems: action.payload,
        filterdItems: action.payload,
        searchItem: '',
      };
    case actionTypes.SET_FILTERED_ITEMS:
      return {
        ...state,
        filterdItems: action.payload,
      };
    case actionTypes.SAVE_SELECTED_ADDON:
      return {
        ...state,
        selectedAddon: action.payload,
      };
    case actionTypes.SET_VEG:
      return {
        ...state,
        veg: action.payload,
      };
    case actionTypes.STORE_TYPE:
      return {
        ...state,
        storeType: action.payload,
      };
    case actionTypes.SET_SEARCH_ITEM:
      return {
        ...state,
        searchItem: action.payload,
      };
    case actionTypes.SET_GLOABL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case actionTypes.SET_ACTIVE_SEARCH:
      return {
        ...state,
        activeSearch: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH:
      return {
        ...state,
        nameSearch: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH_DATA:
      return {
        ...state,
        nameSearchData: action.payload,
      };
    case actionTypes.SET_NAME_SEARCH_DATA_PAGINATION: {
      const temp = {...state.nameSearchData};
      if (temp.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            temp.result.push(res);
          });
      }
      temp['__metadata'] = action.payload.__metadata;
      return {
        ...state,
        nameSearchData: temp,
      };
    }
    case actionTypes.RESET_STORES:
      return {
        orderType: 'delivery',
        availableCategory: [],
        storeList: {
          result: [],
          __metadata: {},
        },
        storeLoading: false,
        categoryLoading: false,
        itemLoading: false,
        selectedStore: {},
        categories: [],
        sourceItems: [],
        filterdItems: [],
        selectedAddon: [],
        veg: false,
        storeType: '',
        searchItem: '',
        globalSearch: {
          result: [],
          __metadata: {},
        },
        activeSearch: 'stores',
        nameSearch: false,
        nameSearchData: {
          result: [],
          __metadata: {},
        },
      };
    default:
      return state;
  }
}
