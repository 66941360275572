import {Realtime} from 'ably/browser/static/ably-commonjs.js';
import {ABLY_KEY} from '../constants';
import {store} from '../../services/store';

window.Ably = new Realtime({
  key: ABLY_KEY,
  recover: function (_, cb) {
    cb(true);
  },
});

const selectOrder = (state) => {
  return state.order.order && state.order.order._id;
};

let currentOrder;

const handleOrderChange = () => {
  const prev = currentOrder;
  currentOrder = selectOrder(store.getState());
  if (prev !== currentOrder) {
    window.orderChannel = window.Ably.channels.get(currentOrder);
  }
};

store.subscribe(handleOrderChange);

const selectUser = (state) => {
  return (
    state.auth.userData &&
    state.auth.userData.user &&
    state.auth.userData.user._id
  );
};

let currentUser;

const handleChangeUser = () => {
  const prev = currentUser;
  currentUser = selectUser(store.getState());
  if (prev !== currentUser) {
    window.userChannel = window.Ably.channels.get(currentUser);
  }
};

store.subscribe(handleChangeUser);
