import * as actionTypes from '../../actionTypes';
import {http} from '../../http';
import axios from 'axios';
import {API_URL, RZP_KEY, UI_PATH} from '../../../utils/constants';
import {toast} from 'react-toastify';
import {push, goBack} from 'connected-react-router';

export const saveOrder = (order) => ({
  type: actionTypes.SAVE_ORDER,
  payload: order,
});

export const setOrderAddress = (address) => ({
  type: actionTypes.SELECTED_ORDER_ADDRESS,
  payload: address,
});

export const setPaymentFetch = (status) => ({
  type: actionTypes.FETCH_PAYMENT_STATUS,
  payload: status,
});

export const setBargainValue = (value) => ({
  type: actionTypes.SET_BARGAIN_VALUE,
  payload: value,
});

export const createOrder = (cb, affiliateID) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.LOADING_ORDER,
      payload: true,
    });
    const cart = getState().cart.localCart;
    const location =
      getState().order.orderAddress && getState().order.orderAddress._id;
    const orderType = getState().stores.orderType
      ? getState().stores.orderType
      : 'delivery';
    const data = {
      items: [],
      platform: 'marketmyindia',
      __type: orderType,
      store_id: cart[0].store_id,
    };
    if (orderType === 'delivery') {
      data['user_address_id'] = location;
    }
    if (affiliateID) {
      data['affiliator_id'] = affiliateID;
    }
    cart.length > 0 &&
      cart.forEach((item) => {
        const temp = {};
        temp['item_id'] = item._id;
        temp['addons'] = [];
        temp['qty'] = item.qty;
        temp['note'] = item.note;
        if (item.addons && item.addons.length > 0) {
          const tempAddon = [];
          item.addons.forEach((addon) => {
            if (addon.addon_items && addon.addon_items.length > 0) {
              const add = {
                _id: addon._id,
                addon_items: [],
              };
              addon.addon_items.forEach((addonItem) => {
                add['addon_items'].push({_id: addonItem._id});
              });
              tempAddon.push(add);
            }
          });
          temp['addons'] = tempAddon;
        }
        data['items'].push(temp);
      });
    http
      .post(`${API_URL}/orders`, data)
      .then(async (res) => {
        await dispatch(saveOrder(res.data));
        if (cb) {
          await cb();
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(async (err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const payCOD = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/pay`, {
        payment_mode: 'cod',
        platform: 'marketmyindia',
      })
      .then(async (res) => {
        await dispatch(saveOrder(res.data));
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const payOnline = (orderID, data, cb, card) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .post(`${API_URL}/orders/${orderID}/pay`, data)
      .then(async (res) => {
        await dispatch(saveOrder(res.data));
        await dispatch({
          type: actionTypes.LOADING_ORDER,
          payload: false,
        });
        if (data.online_mode === 'card' && cb && !card) {
          await cb(res.data.payment.online);
        }
        if (data.online_mode === 'card' && cb && card) {
          await cb(res.data.payment.online, card);
        }
        if (data.online_mode === 'upi') {
          if (
            res.data.payment &&
            res.data.payment.online &&
            res.data.payment.online.aggregator === 'bharatpe'
          ) {
            dispatch(setPaymentFetch(true));
          }
          if (!data.payerVpa) {
            if (
              res.data.payment &&
              res.data.payment.online &&
              res.data.payment.online.order_id
            ) {
              const link = document.createElement('a');
              link.href = res.data.payment.online.order_id;
              document.body.appendChild(link);
              link.click();
              dispatch(setPaymentFetch(true));
            }
          }
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const razorPayCardCheckout = (rzp, card) => {
  return (dispatch, getState) => {
    const razorpay = new window.Razorpay({
      key: RZP_KEY,
      callback_url: `${API_URL}/payments/razorpay/checkout?url=${UI_PATH}/profile`,
      redirect: false,
    });
    const user = getState().auth.userData;
    const data = {
      order_id: rzp.order_id,
      amount: rzp.amount,
      contact: user.phone,
      method: 'card',
      'card[name]': card.name,
      'card[number]': card.number,
      'card[cvv]': card.cvv,
      'card[expiry_month]': card.validity.split('/')[0],
      'card[expiry_year]': card.validity.split('/')[1],
    };
    if (razorpay) {
      razorpay.createPayment(data);
      razorpay.on('payment.success', function (res) {
        dispatch({type: actionTypes.CLEAR_CART});
        dispatch({type: actionTypes.RESET_ORDER});
        dispatch(push('/profile'));
      });
      razorpay.on('payment.error', function (err) {
        toast.error(err.error.description);
      });
    }
  };
};

export const updateCurrentOrder = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then(async (res) => {
        if (
          (res.data.curr_state === 'payment-initalized' &&
            res.data.payment.mode &&
            res.data.payment.mode !== 'online') ||
          res.data.curr_state === 'payment-captured' ||
          res.data.paid
        ) {
          if (window.location.pathname !== '/profile') {
            await dispatch(push('/profile'));
            await dispatch({type: actionTypes.CLEAR_CART});
            await dispatch({type: actionTypes.RESET_ORDER});
            await dispatch({
              type: actionTypes.FETCH_PAYMENT_STATUS,
              payload: false,
            });
          }
        } else {
          await dispatch(saveOrder(res.data));
        }
        await dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          toast.error(err.response.data.message);
        }
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const ablyAck = (id) => {
  return () => {
    axios
      .put(`${API_URL}/ably/${id}`, {ack: true})
      .then(() => console.log('acknowledged'))
      .catch(() => console.log('unable to ack'));
  };
};

export const getPreviousOrders = () => async (dispatch, getState) => {
  dispatch({type: actionTypes.GET_PREV_ORDERS_PENDING});
  const userID =
    getState().auth.userData &&
    getState().auth.userData.user &&
    getState().auth.userData.user._id;
  try {
    const res = await http.get(`${API_URL}/orders/user/${userID}`);
    dispatch({type: actionTypes.GET_PREV_ORDERS_SUCCESS, payload: res.data});
  } catch (err) {
    dispatch({type: actionTypes.GET_PREV_ORDERS_FAILED, payload: err});
  }
};

export const getPreviousOrdersPagination = (url) => {
  return (dispatch) => {
    http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PREV_ORDERS_PAGINATION,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
};

export const getOrderDetails = (orderID) => async (dispatch, getState) => {
  dispatch({type: actionTypes.GET_ORDER_DETAILS_PENDING});
  const currentOrder = getState().order.order && getState().order.order._id;
  try {
    const res = await http.get(`${API_URL}/orders/${orderID}`);
    await dispatch({
      type: actionTypes.GET_ORDER_DETAILS_SUCCESS,
      payload: res.data,
    });
    if (currentOrder && currentOrder === orderID && res.data.paid) {
      dispatch(updateCurrentOrder(orderID));
    }
  } catch (err) {
    dispatch({type: actionTypes.GET_ORDER_DETAILS_FAILED, payload: err});
  }
};

export const resetOrderDetails = () => {
  return {type: actionTypes.GET_ORDER_DETAILS_SUCCESS, payload: null};
};

export const getCoupon = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}/vouchers`)
      .then((res) => {
        dispatch({type: actionTypes.SAVE_COUPONS, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const getTrackOrder = (orderID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    http
      .get(`${API_URL}/orders/${orderID}`)
      .then((res) => {
        dispatch({type: actionTypes.SAVE_TRACK_ORDER, payload: res.data});
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};

export const applyCoupon = (orderID, voucherID, type) => {
  return (dispatch) => {
    dispatch({type: actionTypes.LOADING_ORDER, payload: true});
    const data = {
      ref_id: voucherID,
      discount_type: type,
    };
    http
      .put(`${API_URL}/orders/${orderID}`, {
        discount: data,
      })
      .then((res) => {
        dispatch(saveOrder(res.data));
        dispatch(goBack());
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.LOADING_ORDER, payload: false});
      });
  };
};
